let API_URL = process.env.REACT_APP_API_URL;

// 세션 스토리지에서 JWT 토큰 가져오기
function getAuthToken() {
  return sessionStorage.getItem("access_token");
}

// 가맹점 등록 함수
export function registerMerchant(data, thumbnailFile, contentFiles) {
  const formData = new FormData();

  // 데이터를 FormData에 추가
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  // 파일을 FormData에 추가
  thumbnailFile.forEach((file, index) => {
    formData.append("thumbnail", file); 
  });

  contentFiles.forEach((file, index) => {
    formData.append(`content_file_${index + 1}`, file); 
  });

  return fetch(`${API_URL}/merchant`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAuthToken()}`, // JWT 토큰을 헤더에 추가
    },
    body: formData, // FormData로 보냄
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to register merchant");
      });
    }
    return response.json();
  });
}

// 가맹점 등록 함수
export function editMerchant(data, thumbnailFile, contentFiles) {
  const formData = new FormData();

  // 데이터를 FormData에 추가
  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  // 파일을 FormData에 추가
  thumbnailFile.forEach((file, index) => {
    formData.append("thumbnail", file); 
  });

  contentFiles.forEach((file, index) => {
    formData.append(`content_file_${index + 1}`, file); 
  });

  return fetch(`${API_URL}/merchant/edit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAuthToken()}`, // JWT 토큰을 헤더에 추가
    },
    body: formData, // FormData로 보냄
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to edit merchant");
      });
    }
    return response.json();
  });
}

// 가맹점 목록 조회 함수
export function fetchMerchant() {
  return fetch(`${API_URL}/merchant`, {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to get Merchant");
      });
    }
    return response.json();
  });
}

// 가맹점 조회 함수
export function fetchMerchantList() {
  return fetch(`${API_URL}/merchant/list`, {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to get Merchant");
      });
    }
    return response.json();
  });
}