import { Link } from "react-router-dom";

function Header({ title, link, linkText }) {
  return (
    <header>
      <Link to={link} className="button back">
        {linkText} 가기
      </Link>
      {title}
    </header>
  );
}

export default Header;
