import React from "react";
import Member from "../pages/mng/Member";
import MerchantSignup from "../pages/mng/Signup";
import Merchant from "../pages/mng/Merchant";
import MerchantEdit from "../pages/mng/Edit";

const Mng = [
  {
    path: "/mng",
    children: [
      {
        path: "member",
        element: <Member />, 
      },
      {
        path: "merchant",
        element: <Merchant />, 
      },
      {
        path: "merchant/signup",
        element: <MerchantSignup />, 
      },
      {
        path: "merchant/edit",
        element: <MerchantEdit />, 
      }
    ],
  },
];

export default Mng;
