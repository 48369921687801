import Header from "../../inc/Header";

function Complete() {
  return (
    <div className="complete sub">
      <Header title="회원가입" link="/" linkText="메인으로" />

      <p>회원가입이 완료되었습니다.</p>
    </div>
  );
}

export default Complete;
