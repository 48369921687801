let API_URL = process.env.REACT_APP_API_URL;

// 세션 스토리지에서 JWT 토큰 가져오기
function getAuthToken() {
  return sessionStorage.getItem("access_token");
}

// 코인 정보 조회
export function getCoinInfo() {
  return fetch(`${API_URL}/coin/info`, {
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  }).then((response) => response.json());
}
