import React, { useState } from "react";
import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { fetchMembers } from "../../api/Member";
import Loading from "../../components/common/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const Member = () => {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();

  const [memberList, setMemberList] = useState([]);

  const [columnDefs] = useState([
    { headerName: '아이디', field: 'id', sortable: true, filter: true },
    { headerName: '이름', field: 'name', sortable: true, filter: true },
    { headerName: '휴대폰', field: 'phone', sortable: true, filter: true },
    { headerName: '이메일', field: 'email', sortable: true, filter: true },
    { headerName: '추천 아이디', field: 'referrer_id', sortable: true, filter: true },
    { headerName: '지갑주소', field: 'wallet_address', sortable: true, filter: true },
  ]);

  const { isLoading } = useQuery('members', fetchMembers, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setMemberList(data.members);
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    }
  });

  return (
    <div className="merchant sub">
      <Header title="회원 목록" link="/myPage" linkText="내 정보" />

      <div className="content">
        <div className="ag-theme-alpine" style={{ height: "100%" }}>
          <AgGridReact rowData={memberList} columnDefs={columnDefs} />
        </div>
      </div>

      <Menu />

      {isLoading && <Loading />}
    </div>
  );
};

export default Member;
