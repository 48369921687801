import React from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { vaildRefID } from "../../../api/Member";
import { useQuery } from "react-query";
import Footer from "../../inc/Footer";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../../components/common/Loading";

function Ref() {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const { id } = useParams(); // useParams를 사용하여 type을 가져옴
  // Base64로 인코딩된 id를 복호화
  const decodedID = atob(id);

  const { isLoading } = useQuery(['vaildRefID', decodedID], () => vaildRefID(decodedID), {
    retry: false,
    onSuccess: (data) => {
      if (!data.success) {
        swal.fire(<p className="swal_text">{data.msg}</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          추천인 아이디 조회 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    }
  });

  return (
    <div className="ref">
      <h1>system group SMART</h1>

      <p>
        추천링크 회원가입페이지 입니다.
        <br/>회원가입을 진행해주세요.
      </p>
      
      <div>
        <Link to="/signup/terms/regular" className="button signup_03">
          회원가입
        </Link>
      </div>

      <Footer />

      {isLoading && <Loading />}
    </div>
  );
}

export default Ref;
