import React from "react";
import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { useLocation } from "react-router-dom";

const MerchantDetail = () => {
  let API_URL = process.env.REACT_APP_API_URL;

  const location = useLocation();
  const merchant = location.state || {}; // state에서 데이터를 받음

  return (
    <div className="merchant sub">
      <Header title="가맹점" link={merchant.link} linkText={merchant.linkText} />

      <div className="content">
        {merchant.content_file_1 && <img src={`${API_URL}/file/${merchant.content_file_1}`} alt="상세 내용" />}
        {merchant.content_file_2 && <img src={`${API_URL}/file/${merchant.content_file_2}`} alt="상세 내용" />}
        {merchant.content_file_3 && <img src={`${API_URL}/file/${merchant.content_file_3}`} alt="상세 내용" />}
        {merchant.content_file_4 && <img src={`${API_URL}/file/${merchant.content_file_4}`} alt="상세 내용" />}
        {merchant.content_file_5 && <img src={`${API_URL}/file/${merchant.content_file_5}`} alt="상세 내용" />}
      </div>

      <Menu />
    </div>
  );
};

export default MerchantDetail;
