import React from "react";

const Modal1 = ({ close, handleAgree }) => {
  return (
    <div className="modal termsModal">
      <button className="close" onClick={close}>
        &times;
      </button>
      <div className="header">고유식별정보처리 동의</div>
      <div className="content">
        
        <dl>
          <dt>[SK텔레콤 귀중]</dt>
          <dd>
            본인은 SK텔레콤(주)(이하 ‘회사’라 합니다)가 제공하는 본인확인서비스(이하 ‘서비스’라 합니다)를 이용하기 위해, 다음과 같이 본인의 개인정보를 회사가 아래 기재된 제3자에게 제공하는 것에 동의합니다.
            <ul>
              <li>
                1. 개인정보를 제공받는 자
                <br/>- NICE평가정보(주), 서울신용평가정보(주)
              </li>
              <li>
                2. 개인정보를 제공받는 자의 개인정보 이용목적
                <br/>- 연계정보(CI)/중복가입확인정보(DI) 생성 및 회사에 제공
              </li>
              <li>
                3. 제공하는 개인정보 항목
                <br/>- 회사가 보유하고 있는 이용자의 주민등록번호
              </li>
              <li>
                4. 개인정보를 제공받는 자의 개인정보 보유 및 이용기간
                <br/>- 연계정보(CI)/중복가입확인정보(DI) 생성 후 즉시 폐기
              </li>
              <li>
                5. 위 개인정보 제공에 동의하지 않으실 경우, 서비스를 이용할 수 없습니다.
              </li>
            </ul>
          </dd>
          <dt>[(주)케이티 귀중]</dt>
          <dd>
            (주)케이티 (이하 "본인확인기관")가 NICE평가정보(주) 또는 LGU플러스㈜(이하 "회사")을 통해 제공하는 휴대폰 본인확인서비를 이용하기 위해, 다음과 같이 본인의 개인정보를 회사가 아래 기재된 제3자에게 제공하는 것에 동의합니다.
            <ul>
              <li>제 1 조[고유식별정보의 처리 동의]<br/>"본인확인기관"은 정보통신망법 제23조의2 제2항에 따라 인터넷상에서 주민등록번호를 입력하지 않고도 본인임을 확인할 수 있는 휴대폰 본인확인서비스를 제공하기 위해 고유식별정보를 처리합니다.</li>
              <li>제 2 조[고유식별정보의 제공 동의]<br/>"본인확인기관 지정 등에 관한 기준(방송통신위원회 고시)"에 따라 "회사"와 계약한 정보통신서비스 제공자 의 연계서비스 및 중복가입확인을 위해 아래와 같이 본인의 고유식별정보를 '다른 본인확인기관'에 제공하는 것에 동의합니다.</li>
            </ul>
          </dd>
          <dd>
            [고유식별정보 제공 동의]
            <ul>
              <li>1. 제공자(본인확인기관)(주)케이티</li>
              <li>2. 제공 받는자(본인확인기관)NICE평가정보(주) 또는 LGU플러스㈜</li>
              <li>3. 제공하는 항목주민등록번호(내국인), 외국인등록번호(국내거주외국인)</li>
              <li>4. 제공 목적CI(연계정보), DI(중복가입확인정보)의 생성 및 전달</li>
              <li>5. 보유 및 이용기간CI(연계정보), DI(중복가입확인정보) 생성 후 즉시 폐기</li>
            </ul>
          </dd>
          <dt>[LGU플러스(주) 귀중]</dt>
          <dd>
            LGU플러스(주)(이하 ‘회사’)가 휴대폰본인확인서비스(이하 ‘서비스’)를 제공하기 위해 고유식별정보를 다음과 같이 제3자에게 제공 및 처리 하는 데에 동의합니다.
            <ul>
              <li>
                1.고유식별정보를 제공받는자
                <br/>- 서울신용평가정보(주), 코리아크레딧뷰로(주)
              </li>
              <li>
                2.고유식별정보를 제공받는자의 목적: 연계정보(CI)와 중복가입확인정보(DI)의 생성 및 ‘회사’ 제공
              </li>
              <li>
                3.고유식별정보 제공 항목: ‘회사’가 보유하고 있는 고객의 주민등록번호와 외국인등록번호
              </li>
              <li>
                4.고유식별정보를 제공받는 자의 보유 및 이용기간: 연계정보(CI) 및 중복가입확인정보(DI) 생성후 즉시 폐기
              </li>
              <li>
                5.상기 고유식별정보 처리에 대한 내용에 동의하지 않으실 경우, ‘서비스’를 이용할 수 없습니다.상기와 같이 고유식별정보 이용 및 처리에 동의합니다.
              </li>
            </ul>
          </dd>
          <dt>[NICE평가정보, LGU플러스㈜ 귀중]</dt>
          <dd>
            NICE평가정보㈜ 또는 LGU플러스㈜(이하 “회사”)가 에스케이텔레콤(주), (주)케이티, LG유플러스(주), (주)케이지모빌리언스, (주)다날 등의 업무 대행을 통해 제공하는 휴대폰본인확인서비스와 관련하여 고객으로부터 수집한 고유식별정보를 이용 및 처리하거나 제3자에게 제공하고자 할 때에는 ‘정보통신망
            이용촉진 및 정보보호 등에 관한 법률’에 따라서 고객으로부터 동의를 받아야 합니다.
          </dd>
          <dd>
            제1조 [고유식별정보의 이용 및 처리 목적] “회사”는 고객의 고유식별정보를 아래의 목적으로 이용 및 처리할 수 있습니다.
            <ul>
              <li>1. 정보통신망법 제23조에서 정한 본인확인서비스 제공</li>
              <li>2. 부정 이용 방지 및 수사의뢰</li>
              <li>3. 휴대폰번호보호서비스 해제 (서비스 가입자에 한함)</li>
            </ul>
          </dd>
          <dd>
            제 2조 [고유식별정보의 이용 및 처리 내용] 에스케이텔레콤(주), (주)케이티, LG유플러스(주), 드림시큐리티, 모빌리언스(주), (주)다날 등의 통신사 또는 통신사의 대행업무를 수행하는 사업자에 정보를 전송하여 방송통신위원회에서 고시로 지정한 휴대폰본인확인기관을 통해 본인여부를 확인하고 결과를
            제공받기 위해 고유식별정보가 이용 및 처리됩니다. - 이용 및 처리되는 고유식별정보 : 주민등록번호(내국인), 외국인등록번호(외국인) 상기와 같이 고유식별정보 이용 및 처리에 동의합니다.
          </dd>
        </dl>
      </div>
      <footer>
        <button type="button" className="button" onClick={handleAgree}>
          동의
        </button>
      </footer>
    </div>
  );
};

export default Modal1;
