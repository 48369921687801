import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // useParams 추가
import Header from "../../inc/Header";
import Popup from "reactjs-popup";
import Modal1 from "./modal/Modal1";
import Modal2 from "./modal/Modal2";
import Modal3 from "./modal/Modal3";
import Modal4 from "./modal/Modal4";
import { niceMain, getNiceError } from "../../../api/Member";
import { useQueryClient } from "react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Terms = () => {
  const swal = withReactContent(Swal);
  const queryClient = useQueryClient();
  const { type } = useParams(); // useParams를 사용하여 type을 가져옴
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    chk_01: false,
    chk_02: false,
    chk_03: false,
    chk_04: false,
  });

  useEffect(() => {
    const allChecked = Object.values(checkedItems).every(Boolean);
    setAllChecked(allChecked);
  }, [checkedItems]);

  const onChange = (e) => {
    const { id, checked } = e.target;
    if (id === "all") {
      setAllChecked(checked);
      setCheckedItems({
        chk_01: checked,
        chk_02: checked,
        chk_03: checked,
        chk_04: checked,
      });
    } else {
      setCheckedItems((prev) => ({
        ...prev,
        [id]: checked,
      }));
    }
  };

  const handleModalAgree = (id) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const goNicePage = async () => {
    const data = await queryClient.fetchQuery(["type", type], () => niceMain(type));

    if (data.error) {
      swal.fire(
        <p className="swal_text">
          회원가입중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    } else {
      document.form_chk.token_version_id.value = data.token_version_id;
      document.form_chk.enc_data.value = data.enc_data;
      document.form_chk.integrity_value.value = data.integrity_value;
      document.form_chk.submit();
    }
  };

  // 본인인증 오류 조회
  let getError = null;

  useEffect(() => {
    getError();
  }, [getError]); // 빈 배열을 의존성 배열로 전달

  // 본인인증 오류 조회
  getError = async () => {
    const data = await queryClient.fetchQuery([], getNiceError);

    if (data.error) {
      swal.fire(<p className="swal_text">{data.error}</p>);
    }
  };

  return (
    <div className="terms sub">
      <Header title="약관동의" link="/" linkText="메인으로" />
      
      <div>
        <h2>아래 약관에 동의하신 후 [다음]을 눌러주세요.</h2>

        <div>
          <ul>
            <li>
              <input type="checkbox" id="all" onChange={onChange} checked={allChecked} />
              <label htmlFor="all">모든 약관에 동의</label>
            </li>
            <li>
              <input type="checkbox" id="chk_01" onChange={onChange} checked={checkedItems.chk_01} />
              <label htmlFor="chk_01">[필수] 서비스 이용약관</label>

              <Popup
                trigger={
                  <button type="button" className="arrow">
                    약관팝업
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <Modal1
                    close={() => {
                      close();
                    }}
                    handleAgree={() => {
                      handleModalAgree("chk_01");
                      close();
                    }}
                  />
                )}
              </Popup>
            </li>
            <li>
              <input type="checkbox" id="chk_02" onChange={onChange} checked={checkedItems.chk_02} />
              <label htmlFor="chk_02">[필수] 개인정보 수집 및 이용 동의</label>

              <Popup
                trigger={
                  <button type="button" className="arrow">
                    약관팝업
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <Modal2
                    close={() => {
                      close();
                    }}
                    handleAgree={() => {
                      handleModalAgree("chk_02");
                      close();
                    }}
                  />
                )}
              </Popup>
            </li>
            <li>
              <input type="checkbox" id="chk_03" onChange={onChange} checked={checkedItems.chk_03} />
              <label htmlFor="chk_03">[필수] 개인정보 제 3자 제공 동의</label>

              <Popup
                trigger={
                  <button type="button" className="arrow">
                    약관팝업
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <Modal3
                    close={() => {
                      close();
                    }}
                    handleAgree={() => {
                      handleModalAgree("chk_03");
                      close();
                    }}
                  />
                )}
              </Popup>
            </li>
            <li>
              <input type="checkbox" id="chk_04" onChange={onChange} checked={checkedItems.chk_04} />
              <label htmlFor="chk_04">[필수] 고유식별정보처리 동의</label>

              <Popup
                trigger={
                  <button type="button" className="arrow">
                    약관팝업
                  </button>
                }
                modal
                nested
              >
                {(close) => (
                  <Modal4
                    close={() => {
                      close();
                    }}
                    handleAgree={() => {
                      handleModalAgree("chk_04");
                      close();
                    }}
                  />
                )}
              </Popup>
            </li>
          </ul>
        </div>

        <button type="button" className="button" disabled={!allChecked} onClick={goNicePage}>
          다음
        </button>
      </div>

      <form name="form_chk" method="post" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb">
        <input type="hidden" name="m" value="service" />
        <input type="hidden" name="token_version_id" value="" />
        <input type="hidden" name="enc_data" value="" />
        <input type="hidden" name="integrity_value" value="" />
      </form>
    </div>
  );
};

export default Terms;
