import { useCallback } from 'react';

function useNumberFormat(locale = 'ko-KR') {
  const formatNumber = useCallback(
    (amount) => {
      return new Intl.NumberFormat(locale).format(amount);
    },
    [locale]
  );

  return formatNumber;
}

export default useNumberFormat;
