import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

function ImageUpload({ maxFiles = 1, onFilesChange, initialFiles = [] }) {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (initialFiles.length > 0) setFiles(initialFiles);
  }, [initialFiles]);  

  useEffect(() => {
    onFilesChange(files);
  }, [files, onFilesChange]);  

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/gif': [],
      'image/png': []
    },
    onDrop: acceptedFiles => {
      if (acceptedFiles.length + files.length > maxFiles) {
        alert(`최대 ${maxFiles}개의 이미지만 업로드할 수 있습니다.`);
        return;
      }

      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          uniqueKey: `${file.name}-${file.lastModified}-${Date.now()}-${Math.random()}` // 고유한 키 생성
        })
      );

      setFiles(prevFiles => [...prevFiles, ...newFiles]);
    },
    maxFiles
  });

  // 파일 삭제 핸들러
  const removeFile = (uniqueKey) => {
    setFiles(prevFiles => prevFiles.filter(file => file.uniqueKey !== uniqueKey));
  };

  const thumbs = files.map(file => (
    <div key={file.uniqueKey}>
      <div>
        <img
          alt={file.name}
          src={file.preview}
          onLoad={() => {
            if (!file.url) URL.revokeObjectURL(file.preview); // 기존 파일의 경우 revoke 하지 않음
          }}
        />
      </div>
      <button onClick={() => removeFile(file.uniqueKey)}>삭제</button> {/* 삭제 버튼 추가 */}
    </div>
  ));

  return (
    <section>
      {files.length < maxFiles && (
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>이미지 업로드</p>
        </div>
      )}
      {fileRejections.length > 0 && (
        <div style={{ color: 'red' }}>
          지원하지 않는 파일 형식입니다. jpg, gif, png만 가능합니다.
        </div>
      )}
      <aside>
        {thumbs}
      </aside>
    </section>
  );
}

export default ImageUpload;
