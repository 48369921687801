import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import useNumberFormat from "../../hooks/useNumberFormat";

const calculateSubtreeData = (nodes) => {
  const nodeMap = {};

  // 모든 노드를 ID를 키로 하는 맵에 저장
  nodes.forEach(level => {
    level.forEach(node => {
      nodeMap[node.id] = { ...node, subMembersCount: 0};
    });
  });

  // 하위 인원 및 포인트 합계 계산
  nodes.flat().reverse().forEach(node => {
    if (node.referrer_id && node.referrer_id !== '-') {
      const parent = nodeMap[node.referrer_id];
      if (parent) {
        parent.subMembersCount += 1 + nodeMap[node.id].subMembersCount;
      }
    }
  });

  return nodeMap;
};

const OrgChart = ({ data }) => {
  let org_data = data.org_data;
  const formatNumber = useNumberFormat();
  const nodeMap = calculateSubtreeData(org_data);

  const buildTreeNodes = (parentId) => {
    return org_data.flat()
      .filter(child => child.referrer_id === parentId)
      .map(child => (
        <TreeNode 
          key={child.id} 
          label={
            <div className="node">
              <dl className={"lv" + child.lv}>
                <dt>
                  <h2>
                      {child.name}
                      <span>{formatNumber(nodeMap[child.id].subMembersCount)}</span>
                  </h2>
                  <p>({child.id})</p>
                </dt>
                <dd>
                  {formatNumber(child.max_points)}
                </dd>
              </dl>
            </div>
          }
        >
          {buildTreeNodes(child.id)}
        </TreeNode>
      ));
  };

  const rootNode = org_data[0][0]; // 최상위 노드를 찾아서 설정

  return (
    <Tree
      lineColor={'#ABABAB'} 
      lineHeight={'19px'}
      label={
      <div className="node">
        <dl className="lv">
          <dt>
            <h2>
                {rootNode.name}
                <span>{formatNumber(nodeMap[rootNode.id].subMembersCount)}</span>
            </h2>
            <p>({rootNode.id})</p>
          </dt>
          <dd>
            {formatNumber(rootNode.max_points)}
          </dd>
        </dl>
      </div>
    }>
      {buildTreeNodes(rootNode.id)}
    </Tree>
  );
};

export default OrgChart;
