import React from "react";

const Modal1 = ({ close, handleAgree }) => {
  return (
    <div className="modal termsModal">
      <button className="close" onClick={close}>
        &times;
      </button>
      <div className="header">개인정보 수집 이용 동의</div>
      <div className="content">
        <dl>
          <dt>1. 개인정보의 수집항목 및 수집방법</dt>
          <dd>
            주식회사 스마트그룹에서는 기본적인 회원 서비스 제공을 위한 필수정보와 정보주체 각각의 기호와 필요에 맞는 서비스를 제공하기 위한 선택정보로 구분하여 다음의 정보를 수집하고 있습니다. 가입정보에 대한 선택정보를 입력하지 않은 경우에도 서비스 이용에는 제한이 없습니다.(단, 실명인증이 필요한
            서비스에서는 실명인증을 하지 않을시 서비스 이용에 제한이 있을 수 있습니다)
          </dd>
          <dd>
            <ul>
              <li className="mb-24">
                <span>가. 수집하는 개인정보의 항목</span>

                <table>
                  <thead>
                    <tr>
                      <th className="bg-blue_04 text-center font-normal">회원구분</th>
                      <th className="bg-blue_04 text-center font-normal">필수항목</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-15">일반회원</td>
                      <td>
                        아이디, 성명, 비밀번호, 휴대폰 번호
                      </td>
                    </tr>
                    <tr>
                      <td>가맹점</td>
                      <td>
                        아이디, 성명, 비밀번호, 휴대폰 번호
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
              <li className="mb-24">나. 컴퓨터에 의해 자동으로 수집되는 정보 홈페이지를 이용할 경우 다음의 정보는 로그인 기록을 통하여 자동적으로 수집·저장됩니다. - 브라우져 종류, 접속로그, IP주소</li>
              <li>다. 개인정보 수집방법 - 홈페이지 회원가입을 통한 수집</li>
            </ul>
          </dd>
          <dt>2. 개인정보의 수집·이용목적</dt>
          <dd>가. 법제업무운영 규정 및 법제처 직제에 따라 정하는 소관 업무의 수행 입안ㆍ심사 업무 처리, 법령해석, 입법 질의ㆍ의견 관리, 개인맞춤형 서비스 지원, 국민법제관의 구성 및 운영, 맞춤형 생활법령정보 서비스 제공</dd>
          <dt>3. 보유·이용기간</dt>
          <dd>회원가입 등을 통해 개인정보의 수집·이용, 제공 등에 대해 동의하신 내용은 언제든지 철회하실 수 있습니다. 회원 탈퇴를 요청하거나 수집/이용목적을 달성하거나 보유/이용기간이 종료한 경우 등의 사유발생시 당해 개인정보를 지체없이 파기합니다.</dd>
          <dd>
            가. 이용자 관리 : 3년 다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 
            <span className="ml-10">1) 관계 법령 위반에 따른 수사·조사 등이 진행중인 경우에는 해당 수사·조사 종료시까지</span>
          </dd>
        </dl>
      </div>
      <footer>
        <button type="button" className="button" onClick={handleAgree}>
          동의
        </button>
      </footer>
    </div>
  );
};

export default Modal1;
