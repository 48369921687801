import React from "react";

const Modal1 = ({ close, handleAgree }) => {
  return (
    <div className="modal termsModal">
      <button className="close" onClick={close}>
        &times;
      </button>
      <div className="header">개인정보 제 3자 제공 동의</div>
      <div className="content">
        <dl>
          <dd>
            주식회사 스마트그룹에서는 원칙적으로 정보주체의 개인정보를 수집·이용 목적으로 명시한 범위 내에서 처리하며, 다음의 경우를 제외하고는 정보주체의 사전 동의 없이는 본래의 목적 범위를 초과하여 처리하거나 제3자에게 제공하지 않습니다. 다만, 제5호부터 제9호까지는 공공기관의 경우로 한정합니다.
          </dd>
          <dd>
            <ul>
              <li>1. 정보주체로부터 별도의 동의를 받는 경우</li>
              <li>2. 다른 법률에 특별한 규정이 있는 경우</li>
              <li>3. 정보주체 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명, 신체, 재산의 이익을 위하여 필요하다고 인정되는 경우</li>
              <li>4. 통계작성 및 학술연구 등의 목적을 위하여 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우 삭제&lt;2020. 2. 4. 개인정보보호법 일부개정&gt;</li>
              <li>5. 개인정보를 목적 외의 용도로 이용하거나 이를 제3자에게 제공하지 아니하면 다른 법률에서 정하는 소관 업무를 수행할 수 없는 경우로서 보호위원회의 심의·의결을 거친 경우</li>
              <li>6. 조약, 그 밖의 국제협정의 이행을 위하여 외국정부 또는 국제기구에 제공하기 위하여 필요한 경우</li>
              <li>7. 범죄의 수사와 공소의 제기 및 유지를 위하여 필요한 경우</li>
              <li>8. 법원의 재판업무 수행을 위하여 필요한 경우</li>
              <li>9. 형 및 감호, 보호처분의 집행을 위하여 필요한 경우</li>
            </ul>
          </dd>
          <dd>※ 정보주체는 개인정보의 제 3자의 제공에 대하여 동의를 거부할 권리가 있습니다. 다만, 이에 대한 동의를 하시지 않을 경우에는 법제처의 서비스 이용 과정에서 불이익을 받을 수 있음을 알려 드립니다.</dd>
        </dl>
      </div>
      <footer>
        <button type="button" className="button" onClick={handleAgree}>
          동의
        </button>
      </footer>
    </div>
  );
};

export default Modal1;
