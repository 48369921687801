import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Menu() {
  const swal = withReactContent(Swal);
  const location = useLocation(); // 현재 URL 경로를 가져옵니다.

  const handleIncompleteMenu = (event) => {
    event.preventDefault(); // 링크 기본 동작을 막음
    swal.fire(<p className="swal_text">준비 중입니다.</p>);
  };

  const mypage = location.pathname === "/myPage" || location.pathname.indexOf("/mng/") !== -1 || location.pathname.indexOf("/edit") !== -1;

  return (
    <nav className="menu">
      <ul>
        <li>
          <Link
            to="/home"
            className={location.pathname === "/home" ? "menu_01 on" : "menu_01"}
          >
            Home
          </Link>
        </li>
        <li>
          <Link to="/signup/terms/regular" className="menu_02" onClick={handleIncompleteMenu}>
            전체메뉴
          </Link>
        </li>
        <li>
          <Link
              to="/merchant"
              className={location.pathname === "/merchant" ? "menu_03 on" : "menu_03"}
            >
            가맹점
          </Link>
        </li>
        <li>
          <Link to="/signup/terms/regular" className="menu_04" onClick={handleIncompleteMenu}>
            커뮤니티
          </Link>
        </li>
        <li>
          <Link
            to="/myPage"
            className={mypage ? "menu_05 on" : "menu_05"}
          >
            내정보
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default Menu;
