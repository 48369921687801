import React, { useState } from "react";
import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { fetchMerchant } from "../../api/Merchant";
import Loading from "../../components/common/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import thumbnailImg from '../../assets/images/thumbnail.png';

const Merchant = () => {
  let API_URL = process.env.REACT_APP_API_URL;
  const swal = withReactContent(Swal);
  const navigate = useNavigate();

  const [merchantList, setMerchantList] = useState([]);

  const { isLoading } = useQuery("merchant", fetchMerchant, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setMerchantList(data.merchants);
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  // 가맹점 클릭 시 이동 처리 함수
  const handleMerchantClick = (merchant) => {
    navigate('/mng/merchant/edit', {
      state: {
        merchant: merchant
      }
    });
  };

  return (
    <div className="merchant sub">
      <Header title="가맹점 목록" link="/myPage" linkText="내 정보" />

      <div className="content">
        {merchantList.length > 0 && (
          <div className="merchant_list">
            {merchantList.map((merchant) => (
              <React.Fragment key={merchant.merchant_key}>
                <div onClick={() => handleMerchantClick(merchant)} style={{ cursor: "pointer" }}>
                  <div>
                    {merchant.thumbnail ? (
                      <img src={`${API_URL}/file/${merchant.thumbnail}`} alt="썸네일" />
                    ) : (
                      <img src={thumbnailImg} alt="썸네일" />
                    )}
                  </div>
                  <div>
                    <h3>{merchant.name}</h3>
                    {merchant.description && <p>{merchant.description}</p>}
                    {merchant.url && (
                      <a href={merchant.url} target="_blank" rel="noreferrer">
                        {merchant.url}
                      </a>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
        {merchantList.length === 0 && <p className="noList">등록된 가맹점이 없습니다.</p>}

        <ul className="link-list mt-20">
          <li>
            <Link to="/mng/merchant/signup" className="mypage_04">
              가맹점 등록
            </Link>
          </li>
        </ul>
      </div>

      <Menu />

      {isLoading && <Loading />}
    </div>
  );
};

export default Merchant;
