let API_URL = process.env.REACT_APP_API_URL;

// 멤버 정보 조회 단건
export function fetchName(id) {
  const encodedId = encodeURIComponent(id);
  return fetch(`${API_URL}/member/id/${encodedId}`).then((response) => response.json());
}

// 멤버 정보 조회 단건
export function fetchPassword(pw) {
  const encodedPw = encodeURIComponent(pw);
  return fetch(`${API_URL}/member/pw/${encodedPw}`, {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to Password");
      });
    }
    return response.json();
  });
}

// 멤버 정보 조회 단건
export function fetchEmail(email) {
  const encodedEmail = encodeURIComponent(email);
  return fetch(`${API_URL}/member/email/${encodedEmail}`).then((response) => response.json());
}

// 멤버 정보 조회 단건
export function fetchPhone(phone) {
  const encodedPhone = encodeURIComponent(phone);
  return fetch(`${API_URL}/member/phone/${encodedPhone}`).then((response) => response.json());
}

// 멤버 목록 조회 함수
export function fetchMembers() {
  return fetch(`${API_URL}/member`, {
    method: "GET",
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to get members");
      });
    }
    return response.json();
  });
}

// 멤버 등록 함수
export function registerMember(memberData) {
  return fetch(`${API_URL}/member`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(memberData),
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to register member");
      });
    }
    return response.json();
  });
}

// 로그인 함수
export function loginMember(memberData) {
  return fetch(`${API_URL}/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(memberData),
  }).then((response) => {
    if (!response.ok) {
      return response.json().then((error) => {
        throw new Error(error.message || "Failed to login member");
      });
    }
    return response.json();
  });
}

// 나이스 본인 인증 호출을 위한 정보 조회
export function niceMain(type) {
  return fetch(`${API_URL}/member/nice/main/${type}`).then((response) => response.json());
}

// 오류 조회
export function getNiceError() {
  return fetch(`${API_URL}/member/nice/error`).then((response) => response.json());
}

// 본인인증 정보 조회
export function getNiceInfo() {
  return fetch(`${API_URL}/member/nice/info`).then((response) => response.json());
}

// 세션 스토리지에서 JWT 토큰 가져오기
function getAuthToken() {
  return sessionStorage.getItem('access_token');
}

// 본인 정보 조회
export function getMemberInfo() {
  return fetch(`${API_URL}/member/info`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  }).then((response) => response.json());
}

// 로그아웃 함수
export function logoutMember() {
  return fetch(`${API_URL}/logout`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  }).then((response) => response.json());
}

// 조직도 조회
export function getOrgChartData() {
  return fetch(`${API_URL}/member/orgChart`, {
    headers: {
      'Authorization': `Bearer ${getAuthToken()}`
    }
  }).then((response) => response.json());
}

// 추천아이디 검사
export function vaildRefID(id) {
  return fetch(`${API_URL}/member/vaildRefID`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({id: id}),
  }).then((response) => {
    return response.json();
  });
}