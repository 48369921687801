import Header from "../inc/Header";
import React, { useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import OrgChartComponents from '../../components/member/OrgChart';
import Loading from "../../components/common/Loading";
import { useQuery } from "react-query";
import { getOrgChartData } from "../../api/Member";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function OrgChart() {
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const chartContainerRef = useRef(null);

  const { data, isLoading } = useQuery('orgChartData', getOrgChartData, {
    retry: false,
    onSuccess: (data) => {
      if (!data.success) {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
      navigate("/myPage");
    }
  });

  useEffect(() => {
    // 가로 스크롤 중앙에 맞추기
    const chartContainer = chartContainerRef.current;

    if (chartContainer) {
      chartContainer.scrollLeft = (chartContainer.scrollWidth - chartContainer.clientWidth) / 2;
    }
  }, [data]);

  return (
    <div className="orgchart sub">
      <Header title="조직도" link="/myPage" linkText="내 정보로" />

      <div className="content" ref={chartContainerRef}>
        {!isLoading && data && <OrgChartComponents data={data} />}
      </div>

      {isLoading && <Loading />}
    </div>
  );
}

export default OrgChart;
