import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { fetchName, fetchEmail, registerMember, getNiceInfo } from "../../../api/Member";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loading from "../../../components/common/Loading";
import Header from "../../inc/Header";

const SignUp = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const swal = withReactContent(Swal);
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    watch,
    setValue,
    setError,
    trigger,
    reset,
  } = useForm();

  const [duplicateId, setDuplicateId] = useState("");
  const [referrerMemberName, setReferrerMemberName] = useState("");
  const [duplicateEmail, setDuplicateEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(""); // 이름 상태
  const [phone, setPhone] = useState(""); // 휴대폰 번호 상태
  
  useEffect(() => {
    reset({"wallet_address": ""});
  }, [reset, referrerMemberName]);

  const fetchReferrer = async (fieldName, target) => {
    const data = await queryClient.fetchQuery(["id", target.value], () => fetchName(target.value));

    if (fieldName === "referrer_id") {
      setReferrerMemberName(data.name || "error");
    } else if (fieldName === "id") {
      setValue(fieldName, target.value);
      setDuplicateId(data.id);

      const on = await trigger(fieldName);
      const inputField = target.closest("div");

      inputField.classList.toggle("on", on);
      inputField.classList.toggle("duplicate", data.id !== "" && data.id !== undefined);
      inputField.classList.toggle("error", !on);
    }
  };

  const setFetchEmail = async (fieldName, target) => {
    const data = await queryClient.fetchQuery(["email", target.value], () => fetchEmail(target.value));

    setValue(fieldName, target.value);
    setDuplicateEmail(data.email);

    const on = await trigger(fieldName);
    const inputField = target.closest("div");

    inputField.classList.toggle("on", on);
    inputField.classList.toggle("duplicate", data.email !== "" && data.email !== undefined);
    inputField.classList.toggle("error", !on);
  };

  const onSubmit = (data) => {
    setLoading(true);
    registerMember(data)
      .then((response) => {
        if (response.success) {
          navigate("/signup/complete");
        } else {
          setLoading(false);
          if (response.message) {
            const firstKey = Object.keys(response.message)[0];
            const firstMessageArray = response.message[firstKey];

            if (Array.isArray(firstMessageArray) && firstMessageArray.length > 0) {
              swal.fire(<p className="swal_text">{firstMessageArray[0]}</p>);
            } else {
              swal.fire(<p className="swal_text">알 수 없는 오류가 발생했습니다.</p>);
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        swal.fire(
          <p className="swal_text">
            회원가입중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      });
  };

  const onValid = async (e) => {
    const fieldName = e.target.name;
    const value = e.target.value.trim();
    const inputField = e.target.closest("div");

    if (value) {
      if (fieldName === "password_chk" && value !== watch("password")) {
        setError("password_chk", { message: "비밀번호가 일치하지 않습니다." });
      } else if (fieldName === "referrer_id" || fieldName === "id") {
        fetchReferrer(fieldName, e.target);
      } else {
        if (fieldName === "email") {
          setFetchEmail(fieldName, e.target);
        }

        setValue(fieldName, e.target.value);
        inputField.classList.toggle("on", await trigger(fieldName));
      }
    } else {
      if (fieldName === "id") {
        setDuplicateId("");
        inputField.classList.remove("on", "error", "duplicate");
      } else {
        if (fieldName === "email") {
          setDuplicateEmail("");
        }
        reset({ [fieldName]: "" });
      }
    }
  };

  const toLowerCase = (e) => {
    setValue(e.target.name, e.target.value.toLowerCase());
  };

  // 본인인증 오류 조회
  let getInfo = null;

  useEffect(() => {
    getInfo();
  }, [getInfo]); // 빈 배열을 의존성 배열로 전달

  // 본인인증 오류 조회
  getInfo = async () => {
    try {
      const data = await queryClient.fetchQuery([], getNiceInfo);

      if (data.error) {
        swal.fire(<p className="swal_text">{data.error}</p>);
        navigate("/");
      } else {
        // 데이터가 정상적으로 반환되었을 때 이름과 휴대폰 번호 설정
        setName(data.name || ""); // 받아온 이름 설정
        setPhone(data.mobileno || ""); // 받아온 휴대폰 번호 설정
        if (data.referrer_id) {
          setValue("referrer_id", data.referrer_id);
          onValid({ target: document.querySelector("input[name=referrer_id]") });
        }
      }
    } catch (error) {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    }
  };

  return (
    <div className="signup sub">
      <Header title="회원가입" link="/" linkText="메인으로" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          <li>
            <div className={classNames("input", { on: name !== "" })}>
              <label htmlFor="name">이름</label>
              <input id="name" {...register("name", { required: "이름을 입력해주세요." })} placeholder="이름입력" value={name} readOnly />
            </div>
          </li>
          <li>
            <div className={classNames("input", { on: phone !== "" })}>
              <label htmlFor="phone">휴대폰</label>
              <input id="phone" {...register("phone", { required: "휴대폰 번호를 입력해주세요." })} placeholder="휴대폰 번호 입력" value={phone} readOnly />
            </div>
          </li>
          <li>
            <div className={classNames("input", { error: errors.email })}>
              <label htmlFor="email">이메일</label>
              <input
                id="email"
                {...register("email", {
                  required: "이메일을 입력해주세요.",
                  pattern: {
                    value: /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    message: "유효한 이메일 주소를 입력해주세요.",
                  },
                })}
                placeholder="이메일 주소 입력"
                onBlur={onValid}
                type="email"
              />
            </div>
            {errors.email && <p>{errors.email.message}</p>}
            {duplicateEmail && <p>사용중인 이메일 입니다.</p>}
          </li>
          <li>
            <div className="input">
              <label htmlFor="id">아이디</label>
              <input
                id="id"
                {...register("id", {
                  required: "아이디을 입력해주세요.",
                  pattern: {
                    value: /^[a-z0-9]{6,12}$/,
                    message: "6~12자 이내 영문 소문자, 숫자만 사용 가능합니다.",
                  },
                })}
                placeholder="아이디 입력"
                onBlur={onValid}
                onChange={toLowerCase}
              />
            </div>
            {errors.id && <p>{errors.id.message}</p>}
            {duplicateId && <p>사용중인 아이디 입니다.</p>}
          </li>
          <li>
            <div className={classNames("input", { error: errors.password })}>
              <label htmlFor="password">비밀번호</label>
              <input
                id="password"
                type="password"
                {...register("password", {
                  required: "비밀번호를 입력해주세요.",
                  pattern: {
                    value: /^(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*_+=?,.-])[A-Za-z\d!@#$%^&*_+=?,.-]{8,20}$/,
                    message: "8~20자 이내 영문 소문자, 숫자, 특수문자를 포함하여 입력해주세요.",
                  },
                })}
                placeholder="비밀번호 입력"
                onBlur={onValid}
              />
            </div>
            {errors.password && <p>{errors.password.message}</p>}
          </li>
          <li>
            <div className={errors.password_chk ? "input error" : "input"}>
              <label htmlFor="password_chk">비밀번호 확인</label>
              <input id="password_chk" type="password" {...register("password_chk", { required: "비밀번호 확인을 입력해주세요." })} placeholder="비밀번호 재입력" onBlur={onValid} />
            </div>
            {errors.password_chk && <p>{errors.password_chk.message}</p>}
          </li>
          <li>
            <div className={classNames("input", { error: referrerMemberName === "error", on: referrerMemberName !== "error" && referrerMemberName !== "" })}>
              <label htmlFor="referrer_id">추천 아이디</label>
              <input id="referrer_id" {...register("referrer_id", { required: "추천 아이디를 입력해주세요." })} placeholder="추천 아이디 입력" onBlur={onValid} onChange={toLowerCase} />
            </div>
            {referrerMemberName === "error" && <p>존재하지 않는 회원 아이디 입니다.</p>}
          </li>
          {referrerMemberName !== "error" && referrerMemberName !== "" && (
            <li>
              <div className="input on">
                <label>추천자 이름</label>
                <span>{referrerMemberName}</span>
              </div>
            </li>
          )}
          <li>
            <div className={classNames("input", { error: errors.wallet_address })}>
              <label htmlFor="wallet_address">MEMETOON 지갑주소</label>
              <input
                id="wallet_address"
                {...register("wallet_address", {
                  validate: value => {
                    if (value && !/^0x[a-fA-F0-9]{40}$/.test(value)) {
                      return "유효한 MEMETOON 지갑주소를 입력해주세요.";
                    }
                    return true;
                  }
                })}
                placeholder="MEMETOON 지갑주소  입력"
                onBlur={onValid}
              />
            </div>
            {errors.wallet_address && <p>{errors.wallet_address.message}</p>}
          </li>
        </ul>

        <footer>
          <button type="submit" className="button" disabled={!isValid || (duplicateId !== undefined && duplicateId !== "") || referrerMemberName === undefined || referrerMemberName === ""}>
            회원가입
          </button>
        </footer>
      </form>
      {loading && <Loading />}
    </div>
  );
};

export default SignUp;
