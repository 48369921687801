import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loginMember } from "../../api/Member";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Footer from "../inc/Footer";
import Loading from "../../components/common/Loading";

function Main() {
  const navigate = useNavigate();
  const swal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // 화면이 뜰 때 localStorage에서 아이디를 불러와 입력 필드에 설정
    const savedId = localStorage.getItem("savedId");
    if (savedId) {
      setValue("id", savedId);
    }
  }, [setValue]);

  const onSubmit = (data) => {
    setLoading(true);
    loginMember(data)
      .then((response) => {
        if (response.success) {
          localStorage.setItem("savedId", data.id);
          sessionStorage.setItem("access_token", response.access_token);
          navigate("/home");
        } else {
          setLoading(false);
          if (response.msg) {
            swal.fire(<p className="swal_text">{response.msg}</p>);
          } else {
            swal.fire(<p className="swal_text">알 수 없는 오류가 발생했습니다.</p>);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        swal.fire(
          <p className="swal_text">
            로그인중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      });
  };

  return (
    <div className="main">
      <h1>system group SMART</h1>

      <div className="login">
        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <div className={classNames("input", { error: errors.id })}>
                <label htmlFor="id">아이디</label>
                <input
                  id="id"
                  {...register("id", {
                    required: "아이디을 입력해주세요.",
                  })}
                  placeholder="아이디 입력"
                />
              </div>
              {errors.id && <p>{errors.id.message}</p>}
            </li>
            <li>
              <div className={classNames("input", { error: errors.password })}>
                <label htmlFor="password">비밀번호</label>
                <input
                  id="password"
                  type="password"
                  {...register("password", {
                    required: "비밀번호를 입력해주세요.",
                  })}
                  placeholder="비밀번호 입력"
                />
              </div>
              {errors.password && <p>{errors.password.message}</p>}
            </li>
            <li>
              <button type="submit" className="button">
                로그인
              </button>
            </li>
          </ul>
        </form>
      </div>

      <div className="signup">
        <Link to="/signup/terms/regular" className="button signup_01">
        회원가입
        </Link>
      </div>

      <Footer />
      {loading && <Loading />}
    </div>
  );
}

export default Main;
