import React, { useState } from "react";
import Header from "../inc/Header";
import Menu from "../inc/Menu";
import { useForm } from "react-hook-form";
import { registerMerchant } from "../../api/Merchant";
import classNames from "classnames";
import Loading from "../../components/common/Loading";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../../components/common/ImageUpload";

const Signup = () => {
  const navigate = useNavigate();
  const swal = withReactContent(Swal);
  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm({
    mode: "onChange", // 실시간 폼 유효성 검사 활성화
  });

  const [loading, setLoading] = useState(false);
  const [thumbnailFile, setThumbnailFile] = useState([]);
  const [contentFiles, setContentFiles] = useState([]);

  const onSubmit = (data) => {
    setLoading(true);

    registerMerchant(data, thumbnailFile, contentFiles)
      .then((response) => {
        if (response.success) {
          swal.fire(<p className="swal_text">등록되었습니다.</p>);
          navigate("/mng/merchant");
        } else {
          setLoading(false);
          if (response.msg) {
            swal.fire(<p className="swal_text">{response.msg}</p>);
          } else {
            swal.fire(<p className="swal_text">알 수 없는 오류가 발생했습니다.</p>);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        swal.fire(
          <p className="swal_text">
            가맹점 등록 중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      });
  };

  const handleThumbnailFilesChange = (files) => {
    setThumbnailFile(files); // 업로드된 파일을 상태로 저장
  };

  const handleContentFilesChange = (files) => {
    setContentFiles(files); // 업로드된 파일을 상태로 저장
  };

  return (
    <div className="merchant sub">
      <Header title="가맹점 등록" link="/mng/merchant" linkText="가맹점 목록" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul>
          <li></li>
          <li>
            <div className={classNames("input", { error: errors.name })}>
              <label htmlFor="name">가맹점 이름</label>
              <input
                id="name"
                {...register("name", {
                  required: "가맹점 이름을 입력해주세요.",
                  pattern: {
                    value: /^.{2,}$/,
                    message: "2자 이상 입력해주세요.",
                  },
                })}
                placeholder="가맹점 이름 입력"
              />
            </div>
            {errors.name && <p>{errors.name.message}</p>}
          </li>
          <li>
            <div className={classNames("input", { error: errors.url })}>
              <label htmlFor="url">홈페이지 주소</label>
              <input 
                id="url" 
                {...register("url", {
                  pattern: {
                    // URL에 대한 정규 표현식 (http, https를 포함한 기본적인 URL 형식 검증)
                    value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                    message: "올바른 홈페이지 주소를 입력해주세요.",
                  },
                })}
                placeholder="홈페이지 주소 입력" />
            </div>
            {errors.url && <p>{errors.url.message}</p>}
          </li>
          <li>
            <div className={classNames("input", { error: errors.description })}>
              <label htmlFor="description">간단한 설명</label>
              <textarea 
                id="description" 
                {...register("description")} 
                placeholder="간단한 설명 입력"></textarea>
            </div>
            {errors.description && <p>{errors.description.message}</p>}
          </li>
          <li>
            <div className="image_upload">
              <span>썸네일 등록</span>
              <ImageUpload onFilesChange={handleThumbnailFilesChange} />
              <p>5MB 이하의 jpg, gif, png 파일만 가능합니다.</p>
            </div>
          </li>
          <li>
            <div className="image_upload">
              <span>가맹점 설명 이미지 등록</span>
              <ImageUpload maxFiles={5} onFilesChange={handleContentFilesChange} />
              <p>5MB 이하의 jpg, gif, png 파일 5개까지 가능합니다.</p>
            </div>
          </li>
          <li className="mt-20">
            <button type="submit" className="button" disabled={!isValid}>
              등록하기
            </button>
          </li>
        </ul>
      </form>

      <Menu />

      {loading && <Loading />}
    </div>
  );
};

export default Signup;
