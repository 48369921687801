import React from "react";
import Header from "../../inc/Header";
import Menu from "../../inc/Menu";

function Index() {
  return (
    <div className="mypage sub">
      <Header title="내 정보" link="/home" linkText="홈으로" />

      <div className="content">
        
      </div>
      
      <Menu />
    </div>
  );
}

export default Index;
