import React, { useState } from "react";
import Header from "../../inc/Header";
import Menu from "../../inc/Menu";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { fetchPassword } from "../../../api/Member";
import { useNavigate } from "react-router-dom";

import Loading from "../../../components/common/Loading";

function Check() {
  const navigate = useNavigate();
  const swal = withReactContent(Swal);
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
    setValue,
    trigger,
    reset,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    fetchPassword(data)
      .then((response) => {
        if (response.success) {
          navigate("/edit");
        } else {
          setLoading(false);
          if (response.msg) {
            swal.fire(<p className="swal_text">{response.msg}</p>);
          } else {
            swal.fire(<p className="swal_text">알 수 없는 오류가 발생했습니다.</p>);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        swal.fire(
          <p className="swal_text">
            비밀번호 확인 중 오류가 발생했습니다.
            <br />
            잠시후 다시 시도해주세요.
          </p>
        );
      });
  };

  const onValid = async (e) => {
    const fieldName = e.target.name;
    const value = e.target.value.trim();
    const inputField = e.target.closest("div");

    if (value) {
      setValue(fieldName, e.target.value);
      inputField.classList.toggle("on", await trigger(fieldName));
    } else {
      reset({ [fieldName]: "" });
    }
  };

  return (
    <div className="edit sub">
      <Header title="내 정보 변경" link="/mypage" linkText="내 정보" />

      <div className="content">
        <h2 className="mb-34 pl-16">개인정보보호를 위해<br/>비밀번호를 다시 한번 확인합니다.</h2>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ul>
            <li>
              <div className={classNames("input", { error: errors.password })}>
                <label htmlFor="password">비밀번호</label>
                <input
                  id="password"
                  type="password"
                  {...register("password", {
                    required: "비밀번호를 입력해주세요.",
                  })}
                  placeholder="비밀번호 입력"
                  onBlur={onValid}
                />
              </div>
              {errors.password && <p>{errors.password.message}</p>}
            </li>
          </ul>

          <button type="submit" className="button mt-29" disabled={!isValid}>
            확인
          </button>
        </form>
      </div>
      
      <Menu />

      {loading && <Loading />}
    </div>
  );
}

export default Check;
