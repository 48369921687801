import Menu from "../inc/Menu";
import React, { useState } from "react";
import { useQuery } from "react-query";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { getMemberInfo } from "../../api/Member";
import { fetchMerchantList } from "../../api/Merchant";
import { getCoinInfo } from "../../api/Coin";
import useNumberFormat from "../../hooks/useNumberFormat";
import Loading from "../../components/common/Loading";
import thumbnailImg from '../../assets/images/thumbnail.png';

function Home() {
  let API_URL = process.env.REACT_APP_API_URL;
  const swal = withReactContent(Swal);
  const navigate = useNavigate();
  const formatNumber = useNumberFormat();

  const [name, setName] = useState(""); // 이름
  const [point, setPoint] = useState(""); // 포인트

  const [coin, setCoin] = useState(""); // 코인
  const [usdt, setUSDT] = useState(""); // usdt
  const [won, setWon] = useState(""); // 원화
  const [merchantList, setMerchantList] = useState([]);

  const { isLoading: isMemberLoading } = useQuery("memberInfo", getMemberInfo, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setName(data.name);
        setPoint(data.max_points);
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  const { isLoading: isCoinLoading } = useQuery("coinInfo", getCoinInfo, {
    enabled: point !== "",
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        const truncatedUSDT = Math.floor(parseFloat(data.usd_price) * 1000000) / 1000000;
        const truncatedWon = Math.floor(parseFloat(data.krw_price) * 100) / 100;

        setUSDT(truncatedUSDT); // usdt
        setWon(truncatedWon); // 원화
        setCoin(formatNumber(Math.floor(point / truncatedWon)));
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  const { isLoading: isMerchantLoding } = useQuery("merchant", fetchMerchantList, {
    retry: false,
    onSuccess: (data) => {
      if (data.success) {
        setMerchantList(data.merchants);
      } else {
        swal.fire(<p className="swal_text">잘못된 접근입니다.</p>);
        navigate("/");
      }
    },
    onError: () => {
      swal.fire(
        <p className="swal_text">
          데이터를 불러오는 중 오류가 발생했습니다.
          <br />
          잠시후 다시 시도해주세요.
        </p>
      );
    },
  });

  const isLoading = isMemberLoading || isCoinLoading || isMerchantLoding;

  // 가맹점 클릭 시 이동 처리 함수
  const handleMerchantClick = (merchant) => {
    navigate('/merchant/detail', {
      state: {
        link: "/home",
        linkText: "홈으로",
        content_file_1: merchant.content_file_1,
        content_file_2: merchant.content_file_2,
        content_file_3: merchant.content_file_3,
        content_file_4: merchant.content_file_4,
        content_file_5: merchant.content_file_5
      }
    });
  };

  return (
    <div className="home">
      <h1>system group SMART</h1>

      <div className="coin-box">
        <h2>
          반갑습니다. <span>{name}</span>님
        </h2>

        <div>
          <h3>MY COIN</h3>
          <p>
            <span>{coin}</span>
            <span>coin</span>
          </p>
        </div>

        <ul>
          <li>
            <h3>USDT</h3>
            <span>${usdt}</span>
          </li>
          <li>
            <h3>원화</h3>
            <span>₩{won}</span>
          </li>
        </ul>
      </div>

      <div className="point-box">
        <h2>MY Point</h2>
        <div>
          <span>누적포인트</span>
          <p>
            {formatNumber(point)}
            <span>Point</span>
          </p>
        </div>
      </div>

      <div className="merchant-box">
        {merchantList.length > 0 && (
          <>
            <h3>가맹점 List</h3>
            <div className="merchant_list">
              {merchantList.map((merchant) => (
                <React.Fragment key={merchant.merchant_key}>
                  <div onClick={() => handleMerchantClick(merchant)} style={{ cursor: "pointer" }}>
                    <div>
                      {merchant.thumbnail ? (
                        <img src={`${API_URL}/file/${merchant.thumbnail}`} alt="썸네일" />
                      ) : (
                        <img src={thumbnailImg} alt="썸네일" />
                      )}
                    </div>
                    <div>
                      <h3>{merchant.name}</h3>
                      {merchant.description && <p>{merchant.description}</p>}
                      {merchant.url && (
                        <a href={merchant.url} target="_blank" rel="noreferrer">
                          {merchant.url}
                        </a>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </div>

      <Menu />

      {isLoading && <Loading />}
    </div>
  );
}

export default Home;
